.principal {
    width: 90vw;
    height: 60vh;
    margin: 20px auto;
    border: 3px solid rgba(122, 188, 50, 0.911);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
.dot {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
  
.carousel {
    position: relative;
    margin-top: 5px;
    height: 50px;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    /* border: 1px solid rgb(114, 241, 250); */
}
  
.content {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}
  